import React from 'react';

export default function About() {
  return (
    <section 
      id="about" 
      className="relative bg-cover bg-center text-center font-tattoo py-20"
      style={{
        backgroundImage: "url('/troublebound--hero.jpg')",
        backgroundAttachment: "fixed", // Enables parallax effect
      }}
    >
      {/* Black overlay */}
      <div className="absolute inset-0 bg-black opacity-90"></div>

      {/* Content on top of overlay */}
      <div className="relative z-10 px-8 lg:px-24 text-white">
        <h1 className="text-5xl md:text-6xl font-bold text-deepRed">Trouble Bound Studio</h1>
        <p className="mt-4 text-lg md:text-xl">
          Original custom tattoos in St. John’s for over 20 years
        </p>
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold uppercase mb-6 mt-12 text-deepRed">About Us</h2>
        <p className="text-base md:text-lg lg:text-xl mb-4">
          For over 20 years, Trouble Bound Studio has been a cornerstone custom tattooing in St. John’s, Newfoundland...
        </p>
        <p className="text-base md:text-lg lg:text-xl mb-4">
          Located on the historic Water Street, we pride ourselves on the highest standards...
        </p>
        <p className="text-base md:text-lg lg:text-xl">
          We invite you to visit and explore the work of our talented artists, each with a distinct style...
        </p>
      </div>
    </section>
  );
}
